import cn from 'clsx'
import React, { useCallback, useState, useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import FormField from "../common/form-field"
import defaultLocale from "../../defaultLocal"
import { getFormFields } from "./utils"
import { client } from "../../utils/client"
import SuccessfulGif from '../../assets/gifs/Successful.gif'
import LoadingIcon from "../../assets/icons/LoadingIcon"

const SignUp = ({ isSmallScreen, setIsSubmittedView, isSubmittedView }) => {
  const { register, handleSubmit, formState, setValue } = useForm();
  const [errorMsg, setErrorMsg] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState('')
// eslint-disable-next-line no-undef
  const reCaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY

  const formClassName = 'md:bg-white bg-transparent border-light-grey-outline md:shadow-login-card rounded-xl px-[25px] w-full max-w-full md:max-w-[380px]'

  const formFields = getFormFields()

  const { errors, isValid, isValidating, isSubmitSuccessful } = formState

  const isSubmitButtonDisabled = useMemo(() => !isValid || isSubmitting, [isValid, isSubmitting])

  const { signup: { getStartedText, submitBtn } } = defaultLocale

  useEffect(() => {
    if(isSubmitSuccessful, isValidating && errorMsg){
      setErrorMsg(null)
    }
  }, [isValidating, isSubmitSuccessful, errorMsg])

  const handleRecaptchaVerify = useCallback((token) => {
    setRecaptchaToken(token)
  }, [])

  const signUp = (formData) => {
    setIsSubmitting(true)
    const { name, phone, email, businessName, industry, monthlyOrders } = formData
    return client.post({
      path: 'get-started',
      data: { 
        name, 
        email, 
        phone, 
        avg_monthly_orders: monthlyOrders.label, 
        brand: businessName, 
        industry: industry.label,
        recaptchaToken 
      },
      config: {
        errorCallBack: (error) => {setErrorMsg(error); setIsSubmitting(false)},
        successCallBack: () => {setIsSubmittedView(true); setIsSubmitting(false)},
        merchantApi: true
      }
    })
  };

  const getSubmitButtonClassName = useCallback(
    () => (!isSubmitButtonDisabled? "w-full mt-5 bg-blue text-white font-Poppins-Bold h-9 rounded-full" : "w-full mt-5 bg-blue opacity-40 text-white font-Poppins-Bold h-9 rounded-full"),
    [isSubmitButtonDisabled],
  )

  const getFormClassName = useCallback(
    () => (errorMsg || !isSubmittedView ? `${formClassName} md:px-8 md:pt-7` : `${formClassName}`),
    [errorMsg, isSubmittedView],
  )

  const SubmittedSuccessfullyContent = () => (
    <div className="bg-white border rounded-md pb-4 sm:pt-[70px] md:pt-0 border-white sm:h-[428px] md:h-[512px] text-center items-center justify-between align-middle flex flex-col" >
      <div className="flex items-center flex-col md:mt-[100px]">
        <img src={SuccessfulGif} className="w-40 h-40" />

        <div className="pt-2 font-Poppins-Bold text-3xl">Submitted</div>
        <div className="pt-3 md:font-Poppins-Medium font-Poppins-Regular text-sm ">Thank you for your interest in Fincart.</div>
        <div className="md:font-Poppins-Medium  font-Poppins-Regular text-sm ">Our sales adviser will contact you shortly.</div>
      </div>
      <a className="underline font-Poppins-SemiBold text-2xs text-blue" href="/">Return to Home Screen</a>
    </div>
  )

  return (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
      <form className={getFormClassName()} onSubmit={handleSubmit(signUp)}>
        <GoogleReCaptcha onVerify={handleRecaptchaVerify} />
        {!isSubmittedView? <>
          <div className="gap-4 flex flex-wrap">
            {!isSmallScreen && (
              <div className="font-Poppins-Bold text-sm">
                {getStartedText}
              </div>
            )}
            {formFields.map(({key, ...fieldProps}) => (
              <FormField key={key} props={fieldProps} register={register} errors={errors}  setValue={setValue}/>
            ))}
          </div>
          <button className={cn(getSubmitButtonClassName(), errorMsg ? 'mb-4' : 'mb-8')} type="submit" disabled={isSubmitButtonDisabled}>
            {isSubmitting? <LoadingIcon /> : submitBtn}
          </button>
          {errorMsg && <div className="mb-4 text-red font-Poppins-SemiBold text-2xs text-center first-letter:capitalize">{errorMsg?.toLowerCase()}</div>}
        </> : <SubmittedSuccessfullyContent /> }
      </form>
    </GoogleReCaptchaProvider>
  )
}

export default SignUp