/* eslint-disable no-undef */
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import GetStartedButton from "../common/get-started-button";
import { FincartLargeLogo, FincartSmallLogo } from "../../assets/icons";
import useViewport from "../../hooks/use-viewport";
import defaultLocale from "../../defaultLocal";
import redirectToMerchant from "../../utils/redirect-to-merchant";

const Layout = () => {

  const { isSmallScreen } = useViewport()
  const navigate = useNavigate();

  useEffect(() => {
    const AuthData = localStorage.getItem('fincartLoginData')

    if(AuthData) { redirectToMerchant(AuthData)}
  }, [])

  function openInNewTab(url) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <>
      <nav className="bg-light-background fixed w-full z-20 top-0 start-0">
        <stack>
          <div className="p-2 bg-blue text-white font-Poppins-Medium text-sm px-4 py-[7px] text-center">
            <a onClick={() => openInNewTab('https://www.wamda.com/2025/01/egypt-fincart-secures-pres-seed-round-led-plus-vc')} >
              {defaultLocale.announcement} <span className="underline">Read More</span>
            </a>
          </div>
          <div className="flex flex-wrap items-center justify-between mx-auto py-[10px] px-[25px] md:px-[60px]">
            <button onClick={() => navigate('/')}>
              {isSmallScreen ? <FincartSmallLogo /> : <FincartLargeLogo />}
            </button>
            <div className="flex items-center justify-between gap-2.5 md:gap-4">
              <button
                  type="button"
                  className="text-blue bg-white hover:bg-hover font-Poppins-Bold rounded-full text-sm px-4 py-[7px] text-center border border-solid border-blue"
                  onClick={() => navigate('/login')}
              >
                {defaultLocale.login.loginButton}
              </button>
              <GetStartedButton text={defaultLocale.getStartedBtn}/>
            </div>
          </div>
        </stack>
      </nav>

      <Outlet />
    </>
  )
};

export default Layout;