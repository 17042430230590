
import { EmailIcon, PersonIcon, PhoneIcon, HomeIcon } from "../../assets/icons"
import defaultLocale from "../../defaultLocal"
import { INDUSTRY_DROPDOWN_OPTIONS, ORDERS_DROPDOWN_OPTIONS } from "./constants";

const SIGN_UP_FIELDS_IDS = {
  NAME: 'name',
  PHONE: 'phone',
  EMAIL: 'email',
  BUSINESS_NAME: 'businessName',
  INDUSTRY: 'industry',
  MONTHLY_ORDERS: 'monthlyOrders'
}

const EMAIL_REGEX = /^\S+@\S+\.\S+$/;
const PHONE_REGEX = /(?:([+]\d{1,4})[-.\s]?)?(?:[(](\d{1,3})[)][-.\s]?)?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})/;

export const getFormFields = () => {
  const {
    signup: {
      nameFieldLabel, nameFieldPlaceholder, emailFieldLabel, emailFieldPlaceholder,
      phoneFieldLabel, phoneFieldPlaceholder, businessNameFieldLabel, businessNamePlaceholder,
      industryFieldLabel, monthlyOrdersLabel,
    } } = defaultLocale

  return ([
    { key: SIGN_UP_FIELDS_IDS.NAME,
      htmlFor: SIGN_UP_FIELDS_IDS.NAME,
      label: nameFieldLabel,
      placeholder: nameFieldPlaceholder,
      type: 'text',
      prefixIcon: PersonIcon,
      validation: {
        required: true,
      }
    },
    { key: SIGN_UP_FIELDS_IDS.PHONE,
      htmlFor: SIGN_UP_FIELDS_IDS.PHONE,
      label: phoneFieldLabel,
      placeholder: phoneFieldPlaceholder,
      type: 'number',
      prefixIcon: PhoneIcon,
      validation: {
        required: true,
        validate: (value) => PHONE_REGEX.test(value)? true: 'Please enter valid phone'
      }
    },
    { key: SIGN_UP_FIELDS_IDS.EMAIL,
      htmlFor: SIGN_UP_FIELDS_IDS.EMAIL,
      label: emailFieldLabel,
      placeholder: emailFieldPlaceholder,
      type: 'text',
      prefixIcon: EmailIcon,
      validation: {
        required: true,
        validate: (value) => EMAIL_REGEX.test(value)? true: 'Please enter valid email'
      }
    },
    { key: SIGN_UP_FIELDS_IDS.BUSINESS_NAME,
      htmlFor: SIGN_UP_FIELDS_IDS.BUSINESS_NAME,
      label: businessNameFieldLabel,
      placeholder: businessNamePlaceholder,
      prefixIcon: HomeIcon,
      type: 'text',
      validation: {
        required: true,
      }
    },
    { key: SIGN_UP_FIELDS_IDS.INDUSTRY,
      htmlFor: SIGN_UP_FIELDS_IDS.INDUSTRY,
      label: industryFieldLabel,
      type: 'select',
      options: INDUSTRY_DROPDOWN_OPTIONS,
      validation: {
        required: true,
      }
    },
    { key: SIGN_UP_FIELDS_IDS.MONTHLY_ORDERS,
      htmlFor: SIGN_UP_FIELDS_IDS.MONTHLY_ORDERS,
      label: monthlyOrdersLabel,
      options: ORDERS_DROPDOWN_OPTIONS,
      type: 'select',
      validation: {
        required: true,
      }
    },
  ])
}
