import axios from "axios"

// eslint-disable-next-line no-undef
const BASE_URL = process.env.REACT_APP_BASE_URL
// eslint-disable-next-line no-undef
const MERCHANT_BASE_URL = process.env.REACT_APP_BASE_URL_MERCHANT

const defaultConfig = {
  headers: {
    'Content-Type': 'application/json',
  }
}

const post = ({path, config: { successCallBack, errorCallBack , merchantApi}, data}) => axios.post(`${merchantApi ? MERCHANT_BASE_URL : BASE_URL}${path}`, data, { ...defaultConfig })
  .then((res) => {
    if(res?.data?.status === 'success' && typeof successCallBack === 'function') {
      successCallBack(res)
    }

    return res
  })
  .catch((res) => {
    if(typeof errorCallBack === 'function') {
      errorCallBack(res?.response?.data?.msg || 'Failed to retrieve login info')
    }
    return res
  })

export const client = { post  }
